<template>
  <item
    :config="config"
    :open="open"
    :searchHighlights="searchHighlights"
    :listItem="listItem"
    :narrow="narrow"
    v-on="$listeners"
    class="european-union-regulations-item"
  >
    <div v-if="listItem" class="item__closed-stage">{{ listItem.stage }}</div>
  </item>
</template>

<script>
import Item from '../../components/Item/Item';
import { EuropeanUnionRegulationsItemPrepare } from './util/EuropeanUnionRegulationsItemPrepare';
import { mapActions, mapMutations } from 'vuex';

export default {
  components: { Item },
  metaInfo() {
    return {
      link: [
        {
          rel: 'canonical',
          href: `${window.location.origin}${this.$route.fullPath}`,
        },
      ],
    };
  },
  props: {
    open: Boolean,
    searchHighlights: {
      query: String,
      keywords: Array,
    },
    listItem: Object,
    narrow: Boolean,
  },
  data() {
    return {
      apiModules: {
        moduleId: 'pk',
        modules: 'module_european_union_acts_header',
        favoriteHated: 'favorite_and_hated_european_union_regulations_header',
        favoriteHatedResponse:
          'favorite_and_hateful_european_union_regulations_list',
        list: 'european_union_acts',
        listModules: 'custom_query_european_union_acts_with_names',
        modulesWithName: 'custom_query_european_union_acts_with_names',
      },
      config: {
        routeName: 'european-union-regulations-item',
        routeList: 'european-union-regulations',
        route404: 'european-union-regulations',
        prepareData: EuropeanUnionRegulationsItemPrepare,
        api: {
          body: 'european_union_act_body',
          highlights: 'european_union_acts/load_highlights',
          monitored: 'european_union_acts/load_provisions_with_favorite_flag',
          additional: 'european_union_act_provisions_and_versions',
          textVersions: {
            lastVersion: 'v1/european_union_act_last_version_button',
            version: 'v1/european_union_act_version',
          },
          descriptions: 'v1/european_union_act_other_descriptions',
        },
        bookmark: {
          type: 'european-union-regulations',
          fetch: 'fetchEuropeanUnionRegulations',
          toggle: 'toggleEuropeanUnionRegulations',
        },
        labels: {
          linksTitle: 'Projekt na stronie:',
          linksDoneTitle: 'Akt na stronie:',
        },
      },
    };
  },
  created() {
    if (!this.listItem) {
      this.setApi(this.apiModules);
      this.getFavoriteHated();
      this.setModulesForCurrentList();
    }
  },
  destroyed() {
    if (!this.listItem) {
      this.clearListState();
    }
  },
  methods: {
    ...mapMutations('list', ['setApi', 'clearListState']),
    ...mapActions('list', ['getFavoriteHated', 'setModulesForCurrentList']),
  },
};
</script>

<style lang="scss">
@import './EuropeanUnionRegulationsItem';
</style>
